import React from 'react';
import styled from 'styled-components';
import {message} from 'antd';
import {getOutlet} from 'reconnect.js';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';
import {getUserStateLabel, getUserSubstateLabel} from '../../Utils/UserUtil';
import {formatDateStr} from '../../Utils/TimeUtil';

const PID = '664ab5d1f1ec41ae3421aa71';
const SIGN_FORM_ID = '66ceb64303ce27c96990547f';

function AdminTest(props) {
  const [product, setProduct] = React.useState(null);
  const [downloadUrl, setDownloadUrl] = React.useState(null);
  const [downloadUrl2, setDownloadUrl2] = React.useState(null);
  const [downloadUrl3, setDownloadUrl3] = React.useState(null);
  const [downloadUrl4, setDownloadUrl4] = React.useState(null);

  return (
    <Wrapper>
      <section>
        <h3>手動產出影片觀看紀錄</h3>
        <button
          onClick={async () => {
            try {
              AppActions.setLoading(true);

              const records = await JStorage.fetchAllDocuments(
                'video_attend',
                {
                  product_id: PID,
                },
                ['created'],
              );

              if (records.length > 0) {
                const product = await JStorage.fetchOneDocument('product', {
                  id: PID,
                });
                setProduct(product);

                const owners = records.map((r) => r.user_id);
                const uprs = await JStorage.fetchAllDocuments(
                  'user_profile',
                  {
                    owner: {$in: owners},
                  },
                  ['created'],
                  {name: 1, id_card_number: 1, owner: 1},
                );

                const link = await generateExcelFile(
                  `${product.name}觀看紀錄`,
                  ['序號', '身分證字號', '姓名', '創建日期', '創建時間'],
                  records.map((r, i) => {
                    const upr = uprs.find((upr) => upr.owner === r.user_id);

                    return [
                      `${i + 1}`,
                      upr.id_card_number,
                      upr.name,
                      new Date(r.created).toLocaleDateString('sv'),
                      new Date(r.created).toLocaleTimeString('sv'),
                    ];
                  }),
                );

                if (link) {
                  setDownloadUrl(link);
                }
              }
            } catch (err) {
              console.warn(err);
            } finally {
              AppActions.setLoading(false);
            }
          }}>
          匯出excel
        </button>

        {downloadUrl && (
          <a
            style={{marginLeft: 10, marginRight: 10}}
            download={`${product.name}.xlsx`}
            href={downloadUrl}
            target="_blank"
            rel="noreferrer">
            下載結果
          </a>
        )}

        <button
          onClick={() => {
            getOutlet('admin-import-excel-modal').update({
              title: '匯入實體名單',
              importRecords: async (records) => {
                try {
                  AppActions.setLoading(true);

                  const FIELDS = {
                    name: '姓名',
                    id_card_number: '身分證字號',
                  };

                  const _records = records.map((r) => {
                    const output = {};
                    for (const k in FIELDS) {
                      output[k] = r[FIELDS[k]];
                    }
                    return output;
                  });

                  const idCardNumber = _records.map((r) => r.id_card_number);
                  const uprs = await JStorage.fetchAllDocuments(
                    'user_profile',
                    {id_card_number: {$in: idCardNumber}},
                    null,
                    {
                      owner: 1,
                      id_card_number: 1,
                    },
                  );

                  for (let i = 0; i < _records.length; i++) {
                    const index = uprs.findIndex(
                      (owner) =>
                        owner.id_card_number === _records[i].id_card_number,
                    );

                    _records[i] = {
                      ..._records[i],
                      user_id: uprs[index].owner,
                    };
                  }

                  const instance = await JStorage.fetchOneDocument(
                    'sign_form',
                    {
                      _id: {$oid: SIGN_FORM_ID},
                    },
                  );

                  const nextMembers = [...instance.members];

                  for (let r of _records) {
                    console.warn('r', r);
                    const index = nextMembers.findIndex(
                      (m) => m.owner === r.user_id,
                    );

                    if (index === -1) {
                      nextMembers.push({
                        ...r,
                        is_attend: true,
                        vegetarians: [],
                        insurances: [],
                        is_points_discount: false,
                        points: 0,
                        pay_time: 0,
                        tba_seat: 1,
                        total: 0,
                        is_auto_created: true,
                        note: 'video',
                      });
                    } else {
                      nextMembers[index] = {
                        ...r,
                        ...nextMembers[index],
                        is_attend: true,
                        vegetarians: [],
                        insurances: [],
                        is_points_discount: false,
                        points: 0,
                        pay_time: 0,
                        tba_seat: 1,
                        total: 0,
                        is_auto_created: true,
                        note: 'video',
                      };
                    }
                  }

                  await JStorage.updateDocument(
                    'sign_form',
                    {id: SIGN_FORM_ID},
                    {members: nextMembers},
                  );

                  message.success('匯入成功');
                } catch (err) {
                  console.warn(err);
                  message.error('發生錯誤');
                } finally {
                  AppActions.setLoading(false);
                }
              },
            });
          }}>
          匯入簽到表
        </button>

        <button
          onClick={async () => {
            try {
              AppActions.setLoading(true);
              const records = await JStorage.fetchAllDocuments(
                'video_attend',
                {
                  // product_id: {$ne: PID},
                },
                ['product_id'],
              );

              if (records.length > 0) {
                const products = await JStorage.fetchAllDocuments(
                  'product',
                  {
                    _id: {$in: records.map((r) => ({$oid: r.product_id}))},
                  },
                  ['created'],
                  {_id: 1, name: 1},
                );

                const owners = records.map((r) => r.user_id);
                const uprs = await JStorage.fetchAllDocuments(
                  'user_profile',
                  {
                    owner: {$in: owners},
                  },
                  ['created'],
                  {name: 1, id_card_number: 1, owner: 1},
                );

                const link = await generateExcelFile(
                  `影片觀看達標紀錄`,
                  [
                    '序號',
                    '課程名稱',
                    '身分證字號',
                    '姓名',
                    '創建日期',
                    '創建時間',
                  ],
                  records.map((r, i) => {
                    const upr = uprs.find((upr) => upr.owner === r.user_id);
                    const product = products.find(
                      (product) => product.id === r.product_id,
                    );
                    return [
                      `${i + 1}`,
                      product.name,
                      upr.id_card_number,
                      upr.name,
                      new Date(r.created).toLocaleDateString('sv'),
                      new Date(r.created).toLocaleTimeString('sv'),
                    ];
                  }),
                );

                if (link) {
                  setDownloadUrl2(link);
                }
              }
            } catch (err) {}
          }}>
          下載
        </button>

        {downloadUrl2 && (
          <a
            style={{marginLeft: 10, marginRight: 10}}
            download={`不動產法律影片觀看達標紀錄.xlsx`}
            href={downloadUrl2}
            target="_blank"
            rel="noreferrer">
            下載結果
          </a>
        )}
      </section>

      <section>
        <h3>2024北律會員轉換紀錄</h3>
        <button
          onClick={async () => {
            try {
              AppActions.setLoading(true);

              const records = await JStorage.fetchAllDocuments(
                'user_profile',
                {
                  transforms: {$elemMatch: {TRNS_DATE: {$regex: '2024'}}},
                },
                null,
                {
                  owner: 1,
                  name: 1,
                  id_card_number: 1,
                  transforms: 1,
                  state: 1,
                  substate: 1,
                },
              );

              const nextRecords = [];

              for (let i = 0; i < records.length; i++) {
                const upr = records[i];
                const targetTransforms = upr.transforms.filter(
                  (t) => t.TRNS_DATE.indexOf('2024') > -1,
                );

                for (let n = 0; n < targetTransforms.length; n++) {
                  const t = targetTransforms[n];

                  nextRecords.push([
                    `${i + 1}${
                      targetTransforms.length === 1 ? '' : `-${n + 1}`
                    }`,
                    upr.id_card_number,
                    upr.name,
                    getUserStateLabel(upr.state),
                    getUserSubstateLabel(upr.substate),
                    formatDateStr(new Date(t.TRNS_DATE)).replace(/\//g, '-'),
                    getUserSubstateLabel(t.TYPE),
                  ]);
                }
              }

              const link = await generateExcelFile(
                `2024北律會員轉換紀錄`,
                [
                  '序號',
                  '身分證字號',
                  '姓名',
                  '狀態',
                  '次狀態',
                  '轉換日期',
                  '轉換前',
                ],
                nextRecords,
              );

              if (link) {
                setDownloadUrl3(link);
              }
            } catch (err) {
              console.warn(err);
            } finally {
              AppActions.setLoading(false);
            }
          }}>
          匯出excel
        </button>
        {downloadUrl3 && (
          <a
            style={{marginLeft: 10, marginRight: 10}}
            download={`2024北律會員轉換紀錄.xlsx`}
            href={downloadUrl3}
            target="_blank"
            rel="noreferrer">
            下載結果
          </a>
        )}
      </section>

      <section>
        <h3>北律會費停繳紀錄</h3>
        <button
          onClick={async () => {
            try {
              AppActions.setLoading(true);
              const records = await JStorage.fetchAllDocuments(
                'Annual_Fee',
                {
                  PAY_STATUS: 'S',
                },
                ['-FEE_YEAR', '-BMONTH'],
              );

              const MBR_IDS = [];

              for (let i = 0; i < records.length; i++) {
                const idx = MBR_IDS.findIndex((id) => id === records[i].MBR_ID);

                if (idx === -1) {
                  MBR_IDS.push(records[i].MBR_ID);
                }
              }

              const uprs = await JStorage.fetchAllDocuments(
                'user_profile',
                {
                  ID: {$exists: true, $in: [...MBR_IDS]},
                },
                ['-created'],
                {name: 1, id_card_number: 1, ID: 1},
              );

              const nextRecords = [];

              for (let n = 0; n < records.length; n++) {
                const r = records[n];
                const uprIdx = uprs.findIndex((upr) => upr.ID === r.MBR_ID);

                function getLabel() {
                  if (r.PAY_STATUS === 'N') {
                    return r.REFUND !== 0 ? '退款' : '未繳';
                  } else if (r.PAY_STATUS === 'S') {
                    return '停繳';
                  } else if (r.PAY_STATUS === 'Y') {
                    return '已繳';
                  } else if (r.PAY_STATUS === 'F') {
                    return '免繳';
                  } else {
                    return '未繳';
                  }
                }

                if (uprIdx > -1) {
                  nextRecords.push([
                    `${n + 1}`,
                    uprs[uprIdx].id_card_number,
                    uprs[uprIdx].name,
                    r.FEE_YEAR,
                    r.BMONTH,
                    r.EMONTH,
                    getLabel(),
                    r.RECEIVABLE,
                    r.STOP_DATE.split('T')[0],
                  ]);
                }
              }

              const link = await generateExcelFile(
                `北律會費停繳紀錄`,
                [
                  '序號',
                  '身分證字號',
                  '姓名',
                  '年度',
                  '起',
                  '迄',
                  '繳費狀況',
                  '應收',
                  '停繳日',
                ],
                nextRecords,
              );

              if (link) {
                setDownloadUrl4(link);
              }
            } catch (err) {
              console.warn(err);
            } finally {
              AppActions.setLoading(false);
            }
          }}>
          匯出 Excel
        </button>
        {downloadUrl4 && (
          <a
            style={{marginLeft: 10, marginRight: 10}}
            download={`北律會費停繳紀錄.xlsx`}
            href={downloadUrl4}
            target="_blank"
            rel="noreferrer">
            下載結果
          </a>
        )}
      </section>
    </Wrapper>
  );
}

async function generateExcelFile(name, headers, records) {
  if (!window.XLSX) {
    console.log('no XLSX');
    return;
  }

  try {
    AppActions.setLoading(true);
    const wb = window.XLSX.utils.book_new();
    const ws = window.XLSX.utils.aoa_to_sheet([headers, ...records]);

    window.XLSX.utils.book_append_sheet(wb, ws, name);

    const wbout = window.XLSX.write(wb, {
      bookType: 'xlsx',
      bookSST: false,
      type: 'array',
      cellStyles: true,
      bookImages: true,
    });

    const objUrl = URL.createObjectURL(
      new Blob([wbout], {type: 'application/octet-stream'}),
    );

    await AppActions.delay(600);
    message.success('成功創建下載連結');

    return objUrl;
  } catch (err) {
    console.warn(err);
  } finally {
    AppActions.setLoading(false);
  }
}

const Wrapper = styled.div`
  padding: 20px;

  & > section {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export default AdminTest;
